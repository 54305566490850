import MediaCarousel from "./media-carousel";
import { Card, CardBody } from "@nextui-org/card";
import { InstagramIcon } from "./icons";
import { formatFollowerCount } from "~/utils/misc";
import { Result } from "~/stores/search";
import { prettyLocation } from "~/utils/location";
import posthog from "posthog-js";
import { Link } from "@remix-run/react";
import { track } from "~/utils/track";
import { Skeleton } from "@nextui-org/skeleton";

export default function Provider({
  slug,
  differentiator,
  name,
  images,
  q,
  instagram_followers,
  location_label,
  is_online,
  confidence,
}: Result & { q: string | null }) {
  if (confidence === undefined) {
    return (
      <Card className="flex-shrink-0 w-full h-full relative group overflow-visible">
        <Skeleton className="scale-[1.02] shadow-lg rounded-2xl w-full aspect-square" />
        <CardBody>
          <div className="pt-2">
            <Skeleton className="w-3/4 h-5 rounded-lg" />
            <Skeleton className="w-1/2 h-4 rounded-lg mt-2" />
          </div>
        </CardBody>
      </Card>
    );
  }

  if (
    !name ||
    name.length === 0 ||
    (confidence && confidence !== "high") ||
    (images && images.length === 0)
  ) {
    return null;
  }

  return (
    <Link
      to={`/p/${slug}?q=${q && encodeURIComponent(q)}`}
      onClick={() => {
        posthog.capture("result_opened", {
          query: q,
          name: name,
          differentiator: differentiator,
          instagram_followers: instagram_followers,
          images,
          location_label,
        });
        track("result_opened", {
          query: q,
          name: name,
          differentiator: differentiator,
          instagram_followers: instagram_followers,
          images,
          location_label,
        });
      }}
    >
      <Card className="flex-shrink-0 w-full h-full relative group overflow-visible">
        <div className="scale-[1.02] bg-background shadow-lg rounded-2xl">
          {images && images.length > 0 ? (
            <MediaCarousel media={images} />
          ) : (
            <img
              loading="lazy"
              alt="placeholder"
              className="w-full h-full aspect-square object-cover rounded-2xl"
              src="/assets/blur.webp"
            />
          )}
        </div>
        <CardBody>
          <div className="pt-2">
            <div className="flex items-center justify-between">
              <h4 className="text-left font-semibold text-lg line-clamp-1">
                {name}
              </h4>

              {instagram_followers && instagram_followers > 0 && (
                <div className="flex items-center space-x-2">
                  <InstagramIcon width={20} height={20} />
                  <p className="text-sm font-bold">
                    {formatFollowerCount(instagram_followers)}
                  </p>
                </div>
              )}
            </div>

            <div className="flex items-center space-x-2 divide-x-2">
              <p className="text-sm">
                {[is_online ? "Online" : "", prettyLocation(location_label)]
                  .filter(Boolean)
                  .join(" | ")}
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
    </Link>
  );
}
