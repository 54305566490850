import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import { Virtual } from "swiper/modules";
import "swiper/css";

import { getPublicAssetURL } from "~/utils/assets";
import { cn } from "@nextui-org/react";
import { useEffect, useState } from "react";

const Slide = ({ src }: { src: string }) => {
  const slide = useSwiperSlide();

  if (slide.isActive || slide.isNext || slide.isPrev) {
    return (
      <img
        loading="lazy"
        alt={src}
        className="w-full h-full aspect-square object-cover"
        src={getPublicAssetURL(src)}
      />
    );
  }

  return null;
};

const Thumbs = ({ length }: { length: number }) => {
  const swiper = useSwiper();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    swiper.on("slideChange", () => {
      setActiveIndex(swiper.snapIndex);
    });

    return () => {
      swiper.off("slideChange");
    };
  }, [swiper]);

  return (
    <div className="flex items-center gap-1 absolute bottom-2 left-1/2 -translate-x-1/2 z-10">
      {Array.from({ length }).map((_, i) => (
        <div
          key={i}
          className={cn(
            "w-[6px] h-[6px] rounded-full bg-default-300",
            i === activeIndex && `bg-default-50 w-[8px] h-[8px]`
          )}
        />
      ))}
    </div>
  );
};

export default function MediaCarousel({ media }: { media: string[] }) {
  return (
    <Swiper
      className="w-full h-full aspect-square rounded-xl shadow-md relative"
      slidesPerView={1}
      modules={[Virtual]}
    >
      {media.map((item, index) => (
        <SwiperSlide key={item} virtualIndex={index}>
          <Slide src={item} />
        </SwiperSlide>
      ))}

      <Thumbs length={media.length} />
    </Swiper>
  );
}
